@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --text: #212426;
        --accent: #55C2F9;
        --background: #f2f2f2;
        --background_dark: #2e3234;
    }
}

body{
    background: rgb(85, 194, 249);
    background: linear-gradient(143deg, rgba(85, 194, 249, 0.4) 0%, rgba(242, 242, 242, 1) 50%, rgba(233, 177, 255, 0.4) 100%);
}
